import React from "react";
import "./Footer.css";
import { Images } from "../../Images/ImageIndex";
import { NavLink } from "react-router-dom";


function Footer() {
  function scrollingControl() {
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  return (
    <>
      <div className="footer ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="footer-left">
                <img src={Images.footerlogo} alt="logo" id="footer-logo" />
                <p>
                  Join us at Trouvaille Trips and let us guide you on a
                  remarkable journey of exploration, conservation, and cultural
                  discovery. Together, we can make a difference while creating
                  incredible memories that will last a lifetime.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-right">
                <img src={Images.md} alt="mini-destination" id="md" />
              </div>
            </div>
          </div>
          <div className="row footer-row-1">
            <div className="col-lg-8 ">
              <div className="footer-links">
                <NavLink to="/about" onClick={scrollingControl}>
                  About Us
                </NavLink>
                <NavLink to="/contact" onClick={scrollingControl}>
                  Enquiry Now
                </NavLink>
                <NavLink to="" onClick={scrollingControl}>
                  FAQs &nbsp;&nbsp;&nbsp;&nbsp;
                </NavLink>
                <NavLink to="/policy" onClick={scrollingControl}>
                  Privacy Policy
                </NavLink>
                <NavLink to="" onClick={scrollingControl}>
                  Careers (Coming Soon)
                </NavLink>
                <NavLink to="/sitemap" onClick={scrollingControl}>
                  Sitemap
                </NavLink>
              </div>
            </div>
            <div className="col-lg-4 footer-logo ">
              <div class="social-icons">
                <a
                  href="https://www.facebook.com/"
                  class="social-icon social-icon--facebook"
                >
                  <i class="fa fa-facebook" style={{ fontSize: "28px" }}></i>
                  <div class="tooltip">Facebook</div>
                </a>

                <a
                  href="https://twitter.com/"
                  class="social-icon social-icon--twitter"
                >
                  <i class="fa fa-twitter" style={{ fontSize: "28px" }}></i>
                  <div class="tooltip">Twitter</div>
                </a>

                <a
                  href="https://www.instagram.com/trouvailletripsahmedabad/"
                  class="social-icon social-icon--insta"
                >
                  <i class="fa fa-instagram" style={{ fontSize: "25px" }}></i>
                  <div class="tooltip">Instagram</div>
                </a>

                <a
                  href="https://www.youtube.com/"
                  class="social-icon social-icon--youtube"
                >
                  <i class="fa fa-youtube" style={{ fontSize: "28px" }}></i>
                  <div class="tooltip">Youtube</div>
                </a>
              </div>
            </div>
          </div>
          <div className="row footer-row-1">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-5 last-details ">
                  <div>
                    <div className="row mb-4">
                      <div className="col">
                        <img src={Images.pin} alt="" id="pin" />
                        <span>
                          &nbsp;&nbsp;504 Sai Prasad Apartments, 28 Usmanpura
                          Village, Ahmedabad – 380014
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 last-details">
                  <div>
                    <img src={Images.call} alt="" id="pin" />
                    <span id="no">+91 7486012257</span>
                  </div>
                </div>
                <div className="col-lg-4 last-details">
                  <div>
                    <img src={Images.mail} alt="" id="mail" />
                    <span id="email">darshita@trouvailletrips.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 right-last-details mb-5">
              <span>© Copyright 2023. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
