import React, { useEffect, useState } from "react";
import "./Destination.css";
import { Images } from "../../Images/ImageIndex";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getDestinationById } from "../../Redux-Toolkit/Slices/DestinationByID";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import AccordianForAll, {
  AccordianHead,
  ShowMore,
  ShowLess,
} from "../Accordian/AccordianForAll";

function Destination() {
  const [isVisible, setisVisible] = useState(3);
  const navigate = useNavigate();
  const { id } = useParams();
  
  const Data = useSelector((state) => state.destById);
  const getDest = useSelector((state) => state.destById.dest.data);

  // number of faqs to show
  const [showFaqs, setshowFaqs] = useState(3);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDestinationById(id));
  }, [dispatch, id]);

  // slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function navigateToContact() {
    navigate("/contact");
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  // second header operation start of destination page
  const [activeLink, setActiveLink] = useState("Overview");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  // second header end operation
  const showMore = () => {
    setisVisible((prev) => prev + 3);
  };

  const showLess = () => {
    setisVisible(3);
  };

  // sending props of index as expand or not
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  // train Content
  const [expandTrainReadBtn, setexpandTrainReadBtn] = useState(true);
  const trainContent = expandTrainReadBtn
    ? getDest?.wtr_train.slice(0, 155) + "...."
    : getDest?.wtr_train;

  // Air Content
  const [expandAirReadBtn, setexpandAirReadBtn] = useState(true);
  const AirContent = expandAirReadBtn
    ? getDest?.wtr_flight.slice(0, 150) + "...."
    : getDest?.wtr_flight;

  // Bus Content
  const [expandBusReadBtn, setexpandBusReadBtn] = useState(true);
  const BusContent = expandBusReadBtn
    ? getDest?.wtr_bus.slice(0, 150) + "...."
    : getDest?.wtr_bus;

  return (
    <>
      {/* {Data?.isLoading && <Loader />}
      {!Data.isLoading && Data.isError ? <Error /> : null} */}
      {!Data.isLoading && getDest ? (
        <section className="MainDest-Page">
          <div className="card bg-dark text-white">
            <img
              className="card-img"
              src={getDest?.banner}
              alt="banner"
              id="slider-img"
            />
            <div
              className="card-img-overlay d-flex justify-content-center align-items-center flex-wrap flex-column"
              data-aos="fade-up"
            >
              <h1 className="card-title" id="StateName">
                {getDest?.name}
              </h1>
              <p
                className="card-text text-center"
                id="abt-p"
                dangerouslySetInnerHTML={{ __html: getDest?.tagline }}
              />
            </div>
          </div>
          <div className="dest-titles mt-5 container">
            <a
              href="#overview"
              className={activeLink === "Overview" ? "actives" : "notactive"}
              onClick={() => handleLinkClick("Overview")}
            >
              Overview
            </a>
            <a
              href="#importance"
              className={activeLink === "Importance" ? "actives" : "notactive"}
              onClick={() => handleLinkClick("Importance")}
            >
              Importance
            </a>
            <a
              className={activeLink === "Locations" ? "actives" : "notactive"}
              onClick={() => handleLinkClick("Locations")}
              href="#Locations"
            >
              Locations
            </a>
            <a
              className={activeLink === "Itineraries" ? "actives" : "notactive"}
              onClick={() => handleLinkClick("Itineraries")}
              href="#Itineraries"
            >
              Itineraries
            </a>
            <a
              className={activeLink === "Stay" ? "active" : "notactive"}
              onClick={() => handleLinkClick("Stay")}
              href="#Stay"
            >
              Stay
            </a>
          </div>
          <div className="container mt-5"></div>
          <div
            id="overview"
            className="container myoverview mb-5 mt-5"
            data-aos="fade-up"
          >
            <center>
              <span id="abt-dest">About {getDest?.name}</span>
              <p
                id="abt-desc"
                dangerouslySetInnerHTML={{ __html: getDest?.about }}
              />
            </center>
            <div className="row justify-content-center">
              <div className="col-lg-3">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <center>
                    <img src={Images.mapp} alt="map" />
                    <h6 className="mt-2 basic-details">Capital</h6>
                    <hr />
                    <span className="dest-lang">{getDest?.capital_name}</span>
                  </center>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <center>
                    <img src={Images.pop} alt="map" />
                    <h6 className="mt-2 basic-details">Population</h6>
                    <hr />
                    <span className="dest-lang">{getDest?.population} </span>
                  </center>
                </div>
              </div>
              <div className="col-lg-2 d-flex justify-content-center align-items-center">
                <h1 className="key-facts">Key Facts</h1>
              </div>
              <div className="col-lg-2">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <center>
                    <img src={Images.lang} alt="map" />
                    <h6 className="mt-2 basic-details">Language</h6>
                    <hr />
                    {getDest?.selected_languages.map((item, index) => (
                      <span className="dest-lang" key={item.id}>
                        {item.name}
                        {index < getDest?.selected_languages.length - 1
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </center>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="shadow p-3 mb-5 bg-white rounded">
                  <center>
                    <img src={Images.btime} alt="map" />
                    <h6 className="mt-2 basic-details">Best Time</h6>
                    <hr />
                    <span className="dest-lang">
                      {getDest?.best_time_from} -{getDest?.best_time_to}
                    </span>
                  </center>
                </div>
              </div>
            </div>
          </div>

          {/* start reasons to visit */}
          <div
            className="container reasons mt-5 mb-5"
            id="importance"
            data-aos="fade-up"
          >
            <center>
              <h1 id="myreason">Reason To Visit {getDest?.name}</h1>
            </center>
            <Slider {...settings} className="myslides mt-5 mb-5">
              {getDest?.reasons.map((item) => (
                <div>
                  <div className="row" key={item.id}>
                    <div className="col-lg-6">
                      <center>
                        <img
                          src={item.image_path}
                          alt="sou"
                          id="reasons_to_visit"
                        />
                      </center>
                    </div>
                    <div className="col-lg-6 px-5">
                      <p
                        id="d-p"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* end reasons to visit */}
          <div className="history mt-5 mb-5">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 history-content d-flex flex-column justify-content-center"
                  data-aos="fade-up"
                >
                  <h3 className="mb-4 mt-4 text-white">
                    History of {getDest?.name}
                  </h3>
                  <p
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: getDest?.history,
                    }}
                  />
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center">
                  <img
                    src={Images.imaps}
                    alt="guju"
                    id="guju"
                    className="pt-5"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* places to visit .. */}
          <div id="Locations" className="container">
            <center>
              <h2 className="pb-3 pt-2 places-to-visit mb-5">
                Places to visit in {getDest.name}
              </h2>
            </center>

            {getDest?.places.map((item, index) =>
              index % 2 ? (
                <>
                  <div className="row align-items-center how-it-works d-flex">
                    <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center snake">
                      <div className="circle font-weight-bold">{index + 1}</div>
                    </div>
                    <div className="col-6">
                      <div className="row secondPlaces">
                        <div
                          className="col-lg-8 mt-5 even-details"
                          data-aos="fade-right"
                        >
                          <h5>{item.name}</h5>
                          <p
                            id="secondPlacesDesc"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                        <div className="col-lg-4 col-md-4" data-aos="fade-left">
                          <img
                            src={item.image_path}
                            alt="surat"
                            id="dest-pics"
                            className="even-images"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row timeline">
                    <div className="col-2">
                      <div className="corner top-right"></div>
                    </div>
                    <div className="col-8">
                      <hr />
                    </div>
                    <div className="col-2">
                      <div className="corner left-bottom"></div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row align-items-center justify-content-end how-it-works d-flex">
                    <div className="col-9">
                      <div className="row">
                        <div className="col-lg-7" data-aos="fade-right">
                          <img
                            src={item.image_path}
                            alt="surat"
                            id="dest-pics"
                          />
                        </div>
                        <div className="col-lg-5 mt-5" data-aos="fade-left">
                          <h5>{item.name}</h5>
                          <p
                            id="secondPlacesDesc"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                      <div className="circle font-weight-bold">{index + 1}</div>
                    </div>
                  </div>
                  <div className="row timeline">
                    <div className="col-2">
                      <div className="corner right-bottom"></div>
                    </div>
                    <div className="col-8">
                      <hr />
                    </div>
                    <div className="col-2">
                      <div className="corner top-left"></div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>

          {/* cloud section */}
          <div class="clouds mt-5 mb-0">
            <div class="animation mt-5">
              <div class="cloud cloud1"></div>
              <div class="cloud cloud2"></div>
              <div class="cloud cloud3"></div>
            </div>
            <div class="container">
              <div className="row mt-5">
                <div
                  className="col-lg-4 d-flex align-items-center justify-content-center  flex-column avg"
                  data-aos="fade-up"
                >
                  <h4>Average Spend PP</h4>
                  <svg
                    className="cash-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                  >
                    <g data-name="Cash Money">
                      <path d="M30.29 48H17a1 1 0 0 1-1-1 3 3 0 0 0-3-3 1 1 0 0 1-1-1V31a1 1 0 0 1 1-1 3 3 0 0 0 3-3 1 1 0 0 1 1-1h30a1 1 0 0 1 1 1 3 3 0 0 0 3 3 1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-8v3a1 1 0 0 1 0 2v2.11a1 1 0 0 1-1.72.7 6.07 6.07 0 0 0-6.52-1.38l-.23.09a6 6 0 0 0-3.28 3.76 1 1 0 0 1-.96.72zM17.9 46h11.68a8 8 0 0 1 4.24-4.35l.25-.1a8.2 8.2 0 0 1 6.93.53V42a2 2 0 0 1 .27-1 2 2 0 0 1-.27-1v-3a2 2 0 0 1 2-2h7v-3.1a5 5 0 0 1-3.9-3.9H17.9a5 5 0 0 1-3.9 3.9v10.2a5 5 0 0 1 3.9 3.9z" />
                      <path d="M32 44a7 7 0 1 1 2.79-.58l-.26.1A6.74 6.74 0 0 1 32 44zm0-12a5 5 0 0 0 0 10 4.79 4.79 0 0 0 1.84-.36l.23-.09A5 5 0 0 0 32 32z" />
                      <path d="M30.68 53H12a5 5 0 0 1-5-5V26a5 5 0 0 1 5-5h40a4.17 4.17 0 0 1 .71.06A5 5 0 0 1 57 26v11a1 1 0 0 1-2 0H43v3a1 1 0 0 1 0 2v2.11a1 1 0 0 1-1.72.7 6.07 6.07 0 0 0-6.52-1.38l-.23.09a6 6 0 0 0-3.28 3.76A5.82 5.82 0 0 0 31 49a5.89 5.89 0 0 0 .58 2.56 1 1 0 0 1 0 1 1 1 0 0 1-.9.44zM12 23a3 3 0 0 0-3 3v22a3 3 0 0 0 3 3h17.25a7.83 7.83 0 0 1-.25-2 7.93 7.93 0 0 1 .33-2.29 8 8 0 0 1 4.49-5.06l.25-.1a8.2 8.2 0 0 1 6.93.53V42a2 2 0 0 1 .27-1 2 2 0 0 1-.27-1v-3a2 2 0 0 1 2-2h12v-9a3 3 0 0 0-2.6-3 2 2 0 0 0-.4 0zM56 43a1 1 0 0 1-1-1 1 1 0 0 1 0-2 1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zM56 48a1 1 0 0 1-1-1 1 1 0 0 1 0-2 1 1 0 0 1 2 0v2a1 1 0 0 1-1 1z" />
                      <path d="M20 39a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM52.53 23a.55.55 0 0 1-.18 0 2 2 0 0 0-.35 0h-5.26a1 1 0 0 1-.87-.5L43 17.58a5 5 0 0 1-5.33-1.42l-11.58 6.71a1.06 1.06 0 0 1-.5.13h-10a1 1 0 0 1-1-.74 1 1 0 0 1 .47-1.13l24.23-14A5 5 0 0 1 46.15 9l7.25 12.54a1 1 0 0 1-.87 1.5zm-5.21-2h3.46l-6.37-11a3 3 0 0 0-4.09-1.09L19.32 21h6l12.17-7a1 1 0 0 1 .76-.1 1 1 0 0 1 .61.47A3 3 0 0 0 43 15.44a1 1 0 0 1 .76-.11 1.06 1.06 0 0 1 .61.47z" />
                      <path d="M46.74 23H25.59a1 1 0 0 1-1-.74 1 1 0 0 1 .47-1.13L37.49 14a1 1 0 0 1 .76-.1 1 1 0 0 1 .61.47A3 3 0 0 0 43 15.44a1 1 0 0 1 .76-.11 1.06 1.06 0 0 1 .61.47l3.29 5.7a1 1 0 0 1 0 1 1 1 0 0 1-.92.5zm-17.42-2H45l-2-3.42a5 5 0 0 1-5.33-1.42zM37 57a7.91 7.91 0 0 1-7.22-4.57A7.77 7.77 0 0 1 29 49a7.93 7.93 0 0 1 .33-2.29 8 8 0 0 1 4.49-5.06l.25-.1a8 8 0 0 1 8.65 1.86 7.52 7.52 0 0 1 1.5 2.16 5.81 5.81 0 0 1 .45 1.16A7.69 7.69 0 0 1 45 49a7.93 7.93 0 0 1-.33 2.29 6.22 6.22 0 0 1-.46 1.16 7.49 7.49 0 0 1-1.5 2.15A8 8 0 0 1 37 57zm0-14a6 6 0 0 0-2.24.43l-.23.09a6 6 0 0 0-3.28 3.76A5.82 5.82 0 0 0 31 49a6 6 0 0 0 6 6 6 6 0 0 0 4.28-1.81 5.64 5.64 0 0 0 1.14-1.62 5.21 5.21 0 0 0 .33-.84A5.94 5.94 0 0 0 43 49a5.71 5.71 0 0 0-.25-1.71 4.7 4.7 0 0 0-.32-.84 5.67 5.67 0 0 0-1.14-1.63A6 6 0 0 0 37 43z" />
                      <path d="M55 57H43a2 2 0 0 1-2-2v-1.11a1.05 1.05 0 0 1 .29-.71 5.62 5.62 0 0 0 1.13-1.61 5.21 5.21 0 0 0 .33-.84 1 1 0 0 1 1-.73H55a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2zm-12-2.71V55h12v-3H44.42a4.54 4.54 0 0 1-.21.45A7.51 7.51 0 0 1 43 54.29zM55 52z" />
                      <path d="M55 52H43.71a1 1 0 0 1-.8-.4 1 1 0 0 1-.16-.89 6 6 0 0 0 0-3.42 4.7 4.7 0 0 0-.32-.84 1 1 0 0 1 .89-1.45H55a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2zm-10.06-2H55v-3H44.75a7.77 7.77 0 0 1 .25 2 8.26 8.26 0 0 1-.06 1zM55 47z" />
                      <path d="M55 47H43.32a1 1 0 0 1-.9-.57 5.62 5.62 0 0 0-1.13-1.61 1 1 0 0 1-.29-.71V42a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2zm-11.07-2H55v-3H43v1.71a8.45 8.45 0 0 1 .93 1.29zM55 42z" />
                      <path d="M55 42H43a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2zm-12-5v3h12v-3zm12 0zM37.5 53H35a1 1 0 0 1 0-2h2.5a.5.5 0 0 0 0-1h-1a2.5 2.5 0 0 1 0-5H39a1 1 0 0 1 0 2h-2.5a.5.5 0 0 0 0 1h1a2.5 2.5 0 0 1 0 5z" />
                      <path d="M37 47a1 1 0 0 1-1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1-1 1zM37 54a1 1 0 0 1-1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1-1 1z" />
                    </g>
                  </svg>
                  <h4>₹ {getDest?.avg_spend_pp}</h4>
                  <h6>Excluding Travel Expense </h6>
                </div>
                <div className="col-lg-8 weather-report">
                  <center>
                    <div className="d-flex justify-content-start py-5 flex-column avg">
                      <h4 data-aos="fade-up">
                        Best Time To Visit {getDest.name}
                      </h4>

                      <div className="mt-4 season">
                        <div className="seasons-text" data-aos="fade-up">
                          <img
                            src={Images.winters}
                            alt="winter"
                            id="season-logos"
                          />
                          <h6>
                            {getDest?.best_times.winter_from} -
                            {getDest?.best_times.winter_to}
                          </h6>
                          <h6>
                            {getDest?.best_times.winter_from_temp}&nbsp; -
                            &nbsp;{getDest?.best_times.winter_to_temp}
                          </h6>
                        </div>
                        <div className="seasons-text" data-aos="fade-up">
                          <img
                            src={Images.sun}
                            alt="winter"
                            id="season-logos"
                          />
                          <h6>
                            {getDest?.best_times.summer_from} -{" "}
                            {getDest?.best_times.summer_to}
                          </h6>
                          <h6>
                            {getDest?.best_times.summer_from_temp} &nbsp; -
                            &nbsp;{getDest?.best_times.summer_to_temp}
                          </h6>
                        </div>
                        <div className="seasons-text" data-aos="fade-up">
                          <img
                            src={Images.rain}
                            alt="winter"
                            id="season-logos"
                          />
                          <h6>
                            {getDest?.best_times.monsoon_from} -
                            {getDest?.best_times.monsoon_to}
                          </h6>
                          <h6>
                            {getDest?.best_times.monsoon_from_temp}&nbsp; -
                            &nbsp;{getDest?.best_times.monsoon_to_temp}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
          {/* end cloud section */}

          <div id="Itineraries" className="container">
            <div className="reach-title text-center">
              <h2 id="where-to-stay">Ways to Reach !!!</h2>
            </div>
            <div className="row ways mt-5">
              <div className="col-lg-4 ways-to-go" data-aos="fade-right">
                <img src={Images.train} alt="train" id="" />
                <div class="card shadow-lg bg-white reachCards">
                  <div class="card-body">
                    <span className="card-text" id="ways-head">
                      BY TRAIN
                    </span>
                    <hr />
                    <p
                      class="card-text"
                      dangerouslySetInnerHTML={{
                        __html: trainContent,
                      }}
                    />
                    <button
                      className="readBtn"
                      onClick={() => setexpandTrainReadBtn(!expandTrainReadBtn)}
                    >
                      {expandTrainReadBtn ? "Read More..." : "Read Less"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ways-to-go" data-aos="fade-up">
                <img src={Images.plane} alt="plane" id="" />
                <div class="card shadow-lg bg-white reachCards">
                  <div class="card-body">
                    <span className="card-text" id="ways-head">
                      BY AIR
                    </span>
                    <hr />
                    <p
                      class="card-text"
                      dangerouslySetInnerHTML={{
                        __html: AirContent,
                      }}
                    />
                    <button
                      className="readBtn"
                      onClick={() => setexpandAirReadBtn(!expandAirReadBtn)}
                    >
                      {expandAirReadBtn ? "Read More..." : "Read Less"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ways-to-go" data-aos="fade-left">
                <img src={Images.bus} alt="bus" id="" />
                <div class="card shadow-lg bg-white reachCards">
                  <div class="card-body">
                    <span className="card-text" id="ways-head">
                      BY BUS
                    </span>
                    <hr />
                    <p
                      class="card-text"
                      dangerouslySetInnerHTML={{
                        __html: BusContent,
                      }}
                    />

                    <button
                      className="readBtn"
                      onClick={() => setexpandBusReadBtn(!expandBusReadBtn)}
                    >
                      {expandBusReadBtn ? "Read More..." : "Read Less"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="Stay" className="container">
            <div
              id="box"
              className="border rounded shadow p-4 text-center"
              data-aos="fade-up"
            >
              <h3 className="customized">
                Want an customized itinerary for your journey?
              </h3>
              <button
                type="button"
                className="btn btn-outline-dark btn-lg mt-2"
                onClick={navigateToContact}
              >
                Contact us
              </button>
            </div>

            <div className="container">
              <div className="row mt-5">
                <center>
                  <h2
                    id="where-to-stay"
                    className="mt-5 mb-5"
                    data-aos="fade-up"
                  >
                    Where to stay in {getDest.name} ?
                  </h2>
                </center>
                {getDest?.stays.slice(0, isVisible).map((item) => (
                  <div className="col-lg-4 palaces p-2" data-aos="fade-up">
                    <div className="card shadow-lg bg-white rounded">
                      <img
                        className="card-img-top"
                        src={item.image_path}
                        id="stay_img"
                        alt="Stay"
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center">{item.name}</h5>
                        <hr />
                        <p
                          className="card-text"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <center>
                  {isVisible >= getDest?.stays.length ? (
                    <button id="btnshowmore" onClick={showLess}>
                      <ShowLess />
                    </button>
                  ) : (
                    <button id="btnshowmore" onClick={showMore}>
                      <ShowMore />
                    </button>
                  )}
                </center>
              </div>
            </div>

            {/* start destination accordian section */}
            <AccordianHead />
            {getDest.faqs.slice(0, showFaqs).map((item, index) => (
              <AccordianForAll
                questions={item.question}
                answers={item.answer}
                isExpanded={index === expandedIndex}
                onToggle={() => toggleAccordion(index)}
              />
            ))}
            <center>
              {showFaqs >= getDest?.faqs.length ? (
                <button id="btnshowmore" onClick={() => setshowFaqs(3)}>
                  <ShowLess />
                </button>
              ) : (
                <button
                  id="btnshowmore"
                  onClick={() => setshowFaqs((prev) => prev + 2)}
                >
                  <ShowMore />
                </button>
              )}
            </center>
          </div>
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Destination;
