import React, { useState } from "react";
import "./contact.css";
import Slider from "@mui/material/Slider";
import axios from "axios";

const marks = [
  {
    value: 3000,
    label: "3000",
  },
  {
    value: 100000,
    label: "100000",
  },
];

function valuetext(value) {
  return `${value}`;
}

function Contact() {

  const initialFormData = {
    like_to_go: "",
    when_to_go: "",
    how_many: "",
    price_range: "53000",
    any_other_comment: "",
    fullname: "",
    email: "",
    phone: "",
  };


  const [formData, setFormData] = useState(initialFormData);


  const resetFormData = () => {
    setFormData(initialFormData);
  };
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSliderChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      price_range: `${newValue}`,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // console.log(formData)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contactus`,
        formData
      );
      if (response.status === 200) {
        setShowSuccessModal(true);
        resetFormData();
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

 
  return (
    <>
      <div className="contact">
        <div
          className="container h-100 d-flex flex-column justify-content-center align-items-center text-center"
          data-aos="fade-up"
        >
          <h1 id="StateName">Contact Us</h1>
          <h6 id="abt-p">
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </h6>
        </div>
      </div>
      <div className="container">
        <div className="contact-form" data-aos="fade-up">
          <center>
            <span id="your-trip">Your Trip</span>
          </center>
          <div className="contact-details">
            <form className="carbon-forms" onSubmit={handleSubmit}>
              <label className="mt-4">Where would you like to go?</label>
              <select
                name="like_to_go"
                className="form-select"
                onChange={handleInputChange}
                required
              >
                <option value="" defaultSelected>
                  -- Select option --
                </option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Goa">Goa</option>
              </select>
              <label className="mt-4">When will you like to go?</label>

              <input
                className="form-select cs1"
                name="when_to_go"
                type="date"
                onChange={handleInputChange}
                required
              />

              <label className="mt-4">How many people are travelling?</label>
              <select
                name="how_many"
                className="form-select"
                onChange={handleInputChange}
                required
              >
                <option value="" defaultSelected>
                  -- Select Number --
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <label className="mt-4">Travelling Price Range</label>
              <Slider
                name="price_range"
                defaultValue={formData.price_range}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                marks={marks}
                min={3000}
                max={100000}
                value={formData.price_range}
                onChange={handleSliderChange}
              />
              <label className="mt-4">Any other comments or request?</label>
              <textarea
                name="any_other_comment"
                className="form-control"
                onChange={handleInputChange}
                rows="5"
                placeholder="Enter here......"
                required
              ></textarea>
              <center className="mt-5">
                <span id="your-trip">Your Details</span>
              </center>
              <label className="mt-4">Your Full Name</label>
              <input
                type="text"
                name="fullname"
                className="form-control"
                onChange={handleInputChange}
                placeholder="Enter Full Name"
                required
              />
              <label className="mt-4">Email Address</label>
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={handleInputChange}
                placeholder="Enter Email Address"
                required
              />
              <label className="mt-4">Phone Number</label>
              <div className="input-group mb-3">
                <input
                  type="number"
                  name="phone"
                  className="form-control"
                  onChange={handleInputChange}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  required
                />
              </div>
              <center>
                {loading ? (
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-outline-dark mt-3 mb-3 calculate-foot"
                  >
                    Submit Enquiry
                  </button>
                )}
              </center>
            </form>
          </div>
        </div>
      </div>

      {/* Bootstrap Success Modal */}
      <div
        className={`modal fade ${showSuccessModal ? "show" : ""}`}
        style={{ display: showSuccessModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="successModalLabel"
        aria-hidden={!showSuccessModal}
        onClick={() => setShowSuccessModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="successModalLabel">
                Success!
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowSuccessModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              Your data has been submitted successfully.
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Error Modal */}
      <div
        className={`modal fade ${showErrorModal ? "show" : ""}`}
        style={{ display: showErrorModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorModalLabel"
        aria-hidden={!showErrorModal}
        onClick={() => setShowErrorModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="errorModalLabel">
                Error!
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowErrorModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              There was an error submitting your data.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
