import React, { useEffect, useState } from "react";
import "./Aboutus.css";
import { fetchAbout } from "../../Redux-Toolkit/Slices/BasicPages";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccordianForAll, {
  AccordianHead,
  ShowLess,
  ShowMore,
} from "../Accordian/AccordianForAll";
import Loader from "../Loader/Loader";

function AboutBanner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.basic.isLoading);
  console.log(isLoading)
  const desc = useSelector((state) => state.basic.data.data);


  const aboutContent = useSelector((state) => state.basic.data.data);

  function navigateToContact() {
    navigate("/contact");
    window.scrollTo({ top: 0, behavior: "auto" });
  }
  useEffect(() => {
    dispatch(fetchAbout());
  }, []);

  // sending props of index as expand or not
  const [showFaqs, setshowFaqs] = useState(3);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="AbtBanner"
        style={{
          backgroundImage: `url(${desc?.main_image_path})`,
        }}
      >
        <div
          className="container h-100 d-flex flex-column justify-content-center align-items-center text-center"
          data-aos="fade-up"
        >
          <h1 id="StateName">About Us</h1>

          <div
            className="tagline"
            dangerouslySetInnerHTML={{ __html: desc?.main_description }}
          />

          <span className="mt-4 welcome">Welcome to Trouvaille Trips</span>
        </div>
      </div>

      <div className="container">
        <div className="row start-content">
          <div className="col-lg-5" data-aos="fade-right">
            <p
              dangerouslySetInnerHTML={{
                __html: aboutContent?.sec_one_description,
              }}
            />
          </div>
          <div className="col-lg-7">
            <img src={aboutContent?.sec_one_image_path} alt="img" />
          </div>
        </div>
      </div>
      <div className="whyName">
        <div className="container next-content" data-aos="fade-up">
          <div className="mt-5">
              <span
                id="names"
                className="pt-4"
                dangerouslySetInnerHTML={{
                  __html: aboutContent?.sec_two_title,
                }}
              ></span>
            <p
              dangerouslySetInnerHTML={{
                __html: aboutContent?.sec_two_description,
              }}
            ></p>
          </div>
        </div>
      </div>
      {/* start accordian section */}
      <div className="container mt-4 mb-4 acc">
        <div
          id="box"
          className="border rounded shadow p-4 text-center mb-5 mt-5"
          data-aos="fade-up"
        >
          <h3 className="customized">
            Want an customized itinerary for your journey?
          </h3>
          <button
            type="button"
            className="btn btn-outline-dark btn-lg mt-2"
            onClick={navigateToContact}
          >
            Contact us
          </button>
        </div>

        {/* accordian section */}
        <AccordianHead />
        <div className="main-div mb-5">
          {aboutContent?.faqs &&
            aboutContent.faqs.map((item, index) => (
              <>
                <AccordianForAll
                  questions={item.question}
                  answers={item.answer}
                  isExpanded={index === expandedIndex}
                  onToggle={() => toggleAccordion(index)}
                />
              </>
            ))}
          <center>
            {showFaqs >= aboutContent?.faqs?.length ? (
              <button id="btnshowmore" onClick={() => setshowFaqs(3)}>
                <ShowLess />
              </button>
            ) : (
              <button
                id="btnshowmore"
                onClick={() => setshowFaqs((prev) => prev + 2)}
              >
                <ShowMore />
              </button>
            )}
          </center>
        </div>
      </div>
    </>
  );
}

export default AboutBanner;
