import React from "react";
import OutletAnimation from "./OutletAnimation";

function Bus() {
  return (
    <>
      <OutletAnimation>
        <span id="foot-type-head">Bus</span>
        <div className="container">
          <form action="" className="p-2 carbon-forms">
            <label className="mt-4">From</label>
            <select className="form-select" aria-label="Default select example">
              <option selected className="enterstart">
                Enter Start Point
              </option>
              <option>One</option>
              <option>Two</option>
              <option>Three</option>
            </select>
            <label className="mt-4">To</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <label className="mt-4">Class</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <label className="mt-4">Trips</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <label className="mt-4">Trip Type</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <center>
              <button
                type="submit"
                className="btn btn-lg btn-outline-dark mt-3 mb-3 calculate-foot"
              >
                Calculate
              </button>

              <h5>Total Flights Footprint = 0.85 metric tons of CO2e</h5>
            </center>
          </form>
        </div>
      </OutletAnimation>
    </>
  );
}

export default Bus;
