import "./App.css";
import Aboutus from "./Components/About/Aboutus";
import Footer from "./Components/Footer/Footer";
import HomeSlider from "./Components/Home/HomeSlider";
import Navbar from "./Components/Navbar/Navbar";
import { Routes, Route , Navigate } from "react-router-dom";
import Policy from "./Components/PrivacyPolicy/Policy";
import Sitemap from "./Components/SiteMap/Sitemap";
import { useEffect } from "react";
import FootprintMain from "./Components/Carbon Footprint/FootprintMain";
import Flight from "./Components/Carbon Footprint/Flight";
import Train from "./Components/Carbon Footprint/Train";
import Bus from "./Components/Carbon Footprint/Bus";
import Car from "./Components/Carbon Footprint/Car";
import OurJourney from "./Components/Journey/OurJourney";
import Contact from "./Components/Contact/Contact";
import Destination from "./Components/Destination/Destination";
import AOS from "aos"
import "aos/dist/aos.css";



function App() {

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
    AOS.refresh();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />

      <Routes>
        <Route exact path="/" element={<HomeSlider />} />
        <Route exact path="/about" element={<Aboutus />} />
        <Route exact path="/policy" element={<Policy />} />
        <Route exact path="/sitemap" element={<Sitemap />} />
        <Route exact path="/carbonfootprint" element={<FootprintMain />}>
          <Route index element={<Flight />} />
          <Route exact path="flight" element={<Flight />} />
          <Route exact path="train" element={<Train />} />
          <Route exact path="bus" element={<Bus />} />
          <Route exact path="car" element={<Car />} />
        </Route>
        <Route exact path="/OurJourney" element={<OurJourney />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route path="/destination/:id/:name" element={<Destination />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
