import React, { useState, useEffect } from "react";
import "../Navbar/Navbar.css";
import { Images } from "../../Images/ImageIndex";
import { NavLink, useNavigate } from "react-router-dom";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import { getDestinationDetails } from "../../Redux-Toolkit/Slices/DestinationPages";
import ContactBtn from "../UI/ContactBtn";
import ExploreMoreModal from "../UI/ExploreModal";
import ArrowSVG from "../UI/ArrowSVG";

function Navbar() {
  const dispatch = useDispatch();

  // fetching all destination with detail
  useEffect(() => {
    dispatch(getDestinationDetails());
  }, []);

  // filtereing destiantion ( popular & Active )
  const popularDestination = useSelector(
    (state) => state.destination.popularDestinations
  );
  const activeDestination = useSelector(
    (state) => state.destination.activeDestinations
  );


  const [show, setShow] = useState(false);
  const navigatetohome = useNavigate();
  const handleNavClick = () => {
    setShow(!show);
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const [header, setheader] = useState(false);
  let navigate = useNavigate();

  // contact button navigation
  function ContactButtonNavigation() {
    navigate("/contact");
    setShow(false);
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  // on scroll color change of header
  const scrollHeader = () => {
    if (window.scrollY >= 80) {
      setheader(true);
    } else {
      setheader(false);
    }
  };
  window.addEventListener("scroll", scrollHeader);

  // home navigation onclick of logo
  function navigateToHome() {
    navigatetohome("/");
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  // multi-level drop-down (submenu) toggle
  const [showSubmenu, setshowSubmenu] = useState(null);
  function openDestPlaces(level) {
    setshowSubmenu((e) => (e === level ? null : level));
  }

  // navigate to particular destination
  const navigateToThatPlace = useNavigate();

  function navigateDestination(id, name) {
    const encodedName = encodeURIComponent(name);
    navigateToThatPlace(`/destination/${id}/${encodedName}`);
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }

  // explore more destinations
  const [modal, setmodal] = useState(false);
  useEffect(() => {
    if (modal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [modal]);
  return (
    <>
      <nav
        className={
          header
            ? "navbar navbar-expand-lg navbar-light bg-light fixed-top bg-dark"
            : "navbar navbar-expand-lg navbar-light bg-light fixed-top bg-transparent "
        }
        data-aos="fade-down"
      >
        <div className="container">
          <img
            src={Images.logo}
            alt="logo"
            id="logo"
            onClick={navigateToHome}
          />
          <label className="hamburger">
            <input
              type="checkbox"
              onClick={() => setShow(!show)}
              checked={show}
            />
            <svg viewBox="0 0 32 32">
              <path
                className="line line-top-bottom"
                d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
              ></path>
              <path className="line" d="M7 16 27 16"></path>
            </svg>
          </label>

          <div
            className={
              show
                ? `collapse navbar-collapse show `
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" onClick={handleNavClick}>
                  Start Planning
                </NavLink>
              </li>

              {/* For Desktop Side */}
              <li className="nav-item dropdown desktop-destnations">
                <NavLink to="" className="nav-link active">
                  Destinations
                </NavLink>
                <ul className="dropdown-menu ">
                  {popularDestination?.length > 0 &&
                    popularDestination.map((item) => (
                      <li key={item.id}>
                        <NavLink
                          onClick={() => openDestPlaces(item.id)}
                          data-id={item.id}
                          className="main-dest-names"
                        >
                          {item.name}
                        </NavLink>
                        {showSubmenu === item.id && (
                          <ul className="submenu">
                            <span>Popular Destination</span>
                            <hr />
                            {item?.places.slice(0, 3).map((places) => (
                              <div className="popular-Destinations">
                                <img
                                  src={places.image_path}
                                  id="popular-pics"
                                  alt=""
                                />
                                <span
                                  className="mt-2"
                                  dangerouslySetInnerHTML={{
                                    __html: places.name,
                                  }}
                                />
                              </div>
                            ))}
                            <button
                              className="submitBtn"
                              onClick={() => navigateDestination(item.id,item.name)}
                            >
                              Explore {item.name}
                              <ArrowSVG color="white"/>
                            </button>
                          </ul>
                        )}
                      </li>
                    ))}
                  {/* Explore More Destination */}
                  <button
                    className="exp-more-dest"
                    onClick={() => setmodal(true)}
                  >
                    Explore More &raquo;
                  </button>
                </ul>
              </li>
              {/* end for Desktop Side */}
              {/* For Mobile Side */}
              <li className="nav-item dropdown desktop-mobile">
                <NavLink
                  to=""
                  className="nav-link active"
                  data-bs-toggle="dropdown"
                >
                  Destinations
                </NavLink>
                <ul className="dropdown-menu  scrollable-dropdown">
                  {/* <span id="dest-title">Destination</span> */}
                  {popularDestination?.length > 0 &&
                    popularDestination.slice(0, 3).map((item) => (
                      <li key={item.id}>
                        <NavLink
                          to={`/destination/${item.id}`}
                          onClick={handleNavClick}
                          data-id={item.id}
                          className="main-dest-names"
                        >
                          {item.name} &raquo;
                        </NavLink>
                      </li>
                    ))}
                  <button
                    className="exp-more-dest"
                    onClick={() => setmodal(true)}
                  >
                    Explore More &raquo;
                  </button>
                </ul>
              </li>
              {/* End For Mobile Side */}
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  onClick={handleNavClick}
                  to="/about"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/carbonfootprint"
                  onClick={handleNavClick}
                  className="nav-link active"
                >
                  Carbon Footprint
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/OurJourney"
                  onClick={handleNavClick}
                  className="nav-link active"
                >
                  Our Journey
                </NavLink>
              </li>
              <li className="nav-item">
                <ContactBtn onClick={ContactButtonNavigation} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* explore more destination modal */}
      {modal && (
        <ExploreMoreModal
          activeDestination={activeDestination}
          showModal={modal}
          closeModal={() => setmodal(false)}
        />
      )}
    </>
  );
}

export default Navbar;
