import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


// getting all destination 
export const getDestinationDetails = createAsyncThunk(
  'getDestination',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/destinationsWithDetail`);
    const FilteredPlaces = response.data.data.filter(city => city.status === 1 && city.is_popular === 1);
    const onlyActivePlaces = response.data.data.filter(city => city.status === 1);
    return { FilteredPlaces, onlyActivePlaces };
  }
);



const destinationSlice = createSlice({
  name: "destination",
  initialState: {
    isLoading: false,
    popularDestinations: [],
    activeDestinations: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDestinationDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDestinationDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.popularDestinations = action.payload.FilteredPlaces;
      state.activeDestinations = action.payload.onlyActivePlaces;
    });
    builder.addCase(getDestinationDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.allDest = [];
      state.isError = action.error.message;
    });
  },
});

export default destinationSlice.reducer;
