import React, { useEffect, useState, useRef } from "react";
import "../Home/HomeSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import WhoAreWe from "./WhoAreWe";
import StartJourney from "./StartJourney";
import Brand from "./Brand";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import SliderCards from "./SliderCards";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../../Redux-Toolkit/Slices/homeSlice";

function ArrowButtons({ slideRef }) {
  return (
    <div className="btns">
      <button id="prev" onClick={() => slideRef.current.slickPrev()}>
        <FontAwesomeIcon icon={faArrowLeft} size="lg" color="black" />
      </button>
      <button id="forward" onClick={() => slideRef.current.slickNext()}>
        <FontAwesomeIcon icon={faArrowRight} size="lg" color="black" />
      </button>
    </div>
  );
}

function HomeSlider() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slideRef = useRef(null);

  const { isLoading, data } = useSelector((state) => state.home);
  console.log(data, "data");
  useEffect(() => {
    dispatch(fetchHomeData());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section className="main-home">
        <div className="homesliders">
          <Slider {...settings} ref={slideRef}>
            {data.sliders ?
              data.sliders.map((dest) => (
                <SliderCards
                  key={dest.id}
                  banner={dest.banner}
                  name={dest.name}
                  tagline={dest.tagline}
                  onclick={() => navigate(`/destination/${dest.id}/${dest.name}`)}
                />
              )) : <Loader/>}
          </Slider>
          <div className="btns">
            <ArrowButtons slideRef={slideRef} />
          </div>
         
        </div>
        {/* who are we */}
        <WhoAreWe description={data?.description} image={data?.image_path} />
        {/* start journey */}
        <StartJourney />
        {/* brand */}
        <Brand />
      </section>
    </>
  );
}

export default HomeSlider;
