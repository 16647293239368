import React from 'react'
import "./Brand.css"
import { Images } from '../../Images/ImageIndex'
function Brand() {
  return (
    <>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-12 brand-logo">
            <img src={Images.kroger} alt="kroger"  data-aos="fade-right" data-aos-duration="2000"/>
            <img src={Images.eight} alt="eight" data-aos="fade-right" data-aos-duration="4000"/>
            <img src={Images.global} alt="global" data-aos="fade-zoom-in"  data-aos-duration="5000"/>
            <img src={Images.sony} alt="sony" data-aos="fade-left" data-aos-duration="4000"/>
            <img src={Images.network} alt="network" data-aos="fade-left" data-aos-duration="2000"/>
          </div>
        </div> */}
      </div>

      {/* // reach section */}
      <div className="reach">
        <div className="container">
          <div className="reach-title" >
            <span id="way-to">Way to</span>
            <span id="reach-us">Reach Us</span>
            <p id="reach-p">
              Check out our resources to amp up your game with data-driven
              intelligence and automation.
            </p>
          </div>
          <div className="row ways">
            <div className="col-lg-4" data-aos="fade-up-right" data-aos-duration="30000">
              <img src={Images.train} alt="train" id="gify" />
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It has
                survived not only five centuries.
              </p>
            </div>
            <div className="col-lg-4" data-aos="fade-up" data-aos-duration="30000">
              <img src={Images.plane} alt="plane" id="gify" />
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It has
                survived not only five centuries.
              </p>
            </div>
            <div className="col-lg-4 " data-aos="fade-up-left" data-aos-duration="30000">
              <img src={Images.bus} alt="bus" id="gify" />
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It has
                survived not only five centuries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brand