import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


// about page api
export const fetchAbout = createAsyncThunk(
  "fetchAbout",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_CMS_URL}/about`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// carbon footprint page api
export const fetchCarbonFootPrint = createAsyncThunk(
  "fetchCarbonFootPrint",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_CMS_URL}/carbonfootprint`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// our journey page api
export const fetchOurJourney = createAsyncThunk(
  "fetchOurJourney",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_CMS_URL}/ourjourney`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// our Privacy Policy Page
export const fetchPrivacyPolicy = createAsyncThunk(
  "fetchPrivacyPolicy",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_CMS_URL}/privacypolicy`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const basicSlice = createSlice({
  name: "basic",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAbout.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAbout.rejected, (state, { payload }) => {
      state.isError = payload;
    });
    builder.addCase(fetchCarbonFootPrint.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCarbonFootPrint.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCarbonFootPrint.rejected, (state, { payload }) => {
      state.isError = payload;
    });
    builder.addCase(fetchOurJourney.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOurJourney.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchOurJourney.rejected, (state, { payload }) => {
      state.isError = payload;
    });
    builder.addCase(fetchPrivacyPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrivacyPolicy.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchPrivacyPolicy.rejected, (state, { payload }) => {
      state.isError = payload;
    });
  },
});

export default basicSlice.reducer;
