import { configureStore } from "@reduxjs/toolkit";
import basicSlice from "./Slices/BasicPages";
import destinationSlice from "./Slices/DestinationPages"
import destinationByIDSlice from "./Slices/DestinationByID"
import FaqSlice from "./Slices/FaqSlice";
import homeSlice from "./Slices/homeSlice";


export const store =configureStore({
    reducer:{
        basic: basicSlice,
        home: homeSlice,
        destination: destinationSlice,
        destById : destinationByIDSlice,
        faq: FaqSlice
    },
   
})