import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// get destination by id
export const getDestinationById = createAsyncThunk('getDestinationById',(id) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/destination/${id}`).then(res => res.data)
});


const destinationByIDSlice = createSlice({
  name: "destination",
  initialState: {
    isLoading: false,
    dest: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDestinationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDestinationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dest = action.payload;
    });
    builder.addCase(getDestinationById.rejected, (state, action) => {
      state.isLoading = false;
      state.dest = [];
      state.isError = action.error.message;
    });
  },
});

export default destinationByIDSlice.reducer;
