import React from "react";
import "../Home/WhoAreWe.css";

function WhoAreWe({ description, image }) {
  return (
    <div
      className="container mt-4"
      data-aos="fade-up"
      data-aos-duration="30000"
    >
      <center>
        <span className="who">Who Are We?</span>
      </center>
      <div
        className="who-p"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>

      <div className="row">
        <div className="col-lg-12" data-aos="flip-up" data-aos-duration="30000">
          <center>{<img src={image} alt="who1" id="who1" />}</center>
        </div>
      </div>
    </div>
  );
}

export default WhoAreWe;
