// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".who {\r\n  font-family: \"Lexend\";\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-size: calc(35px + 0.390625vw);\r\n  color: #041022;\r\n}\r\n\r\n\r\n\r\n.who-p {\r\n  padding-top: 20px;\r\n  text-align: center;\r\n  font-family: \"Lexend\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 1rem;\r\n  line-height: 150%;\r\n  letter-spacing: -0.02em;\r\n  color: #041022;\r\n}\r\n\r\n\r\n#who1 {\r\n  width: 100%;\r\n  max-height: auto;\r\n  margin-top: 20px;\r\n  border: 5px solid black;\r\n}\r\n\r\n#who2,#who3{\r\n    width: 450px;\r\n    height: 300px;\r\n    padding-top: 20px;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/Components/Home/WhoAreWe.css"],"names":[],"mappings":"AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;EAClC,cAAc;AAChB;;;;AAIA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;AAChB;;;AAGA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Lexend:wght@300&display=swap\");\r\n\r\n.who {\r\n  font-family: \"Lexend\";\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-size: calc(35px + 0.390625vw);\r\n  color: #041022;\r\n}\r\n\r\n\r\n\r\n.who-p {\r\n  padding-top: 20px;\r\n  text-align: center;\r\n  font-family: \"Lexend\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 1rem;\r\n  line-height: 150%;\r\n  letter-spacing: -0.02em;\r\n  color: #041022;\r\n}\r\n\r\n\r\n#who1 {\r\n  width: 100%;\r\n  max-height: auto;\r\n  margin-top: 20px;\r\n  border: 5px solid black;\r\n}\r\n\r\n#who2,#who3{\r\n    width: 450px;\r\n    height: 300px;\r\n    padding-top: 20px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
