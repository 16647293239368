import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getDestinationFAQ = createAsyncThunk('getDestinationFAQ',() => {
    return axios.get(`${process.env.REACT_APP_BASE_URL}/getDestinationFaqs`).then(res => res.data)
  });


const faqSlice = createSlice({
    name: "faqSlice",
    initialState: {
        faq: []
    },
    extraReducers:(builder => {
        builder.addCase(getDestinationFAQ.fulfilled,(state,action) => {
            state.faq = action.payload
        })
    })
})

export default faqSlice.reducer