import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const fetchHomeData = createAsyncThunk('home/fetchHomeData', async () => {
  const response = await axios.get(`${process.env.REACT_APP_CMS_URL}/home`);
  const { data } = response.data;

  const sliderIDs = [data.slider_1, data.slider_2, data.slider_3, data.slider_4, data.slider_5];

  const destinations = await Promise.all(
    sliderIDs.map(id => 
      axios.get(`${process.env.REACT_APP_BASE_URL}/destination/${id}`).then(response => response.data.data)
    )
  );

  return {
    ...data,
    sliders: destinations
  };
});

const homeSlice = createSlice({
  name: "home",
  initialState: {
    isLoading: false,
    data: {},
    isError: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchHomeData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchHomeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchHomeData.rejected, state => {
        state.isLoading = false;
        state.isError = true;
      });
  }
});

export default homeSlice.reducer;
