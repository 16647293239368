import React from "react";
import "./loader.css";
function Loader() {
  return (
    <>
    <section className="loader">
      <div class="dot-wave">
        <div class="dot-wave__dot"></div>
        <div class="dot-wave__dot"></div>
        <div class="dot-wave__dot"></div>
        <div class="dot-wave__dot"></div>
      </div>
      </section>
    </>
  );
}

export default Loader;
