import React, { useState, useEffect } from "react";
import OutletAnimation from "./OutletAnimation";
import axios from "axios";

function Flight() {
  const [cities, setCities] = useState([]);
  const [city1, setCity1] = useState('');
  const [city2, setCity2] = useState('');
  const [distance, setDistance] = useState(null);
  const [carbonFootprint, setCarbonFootprint] = useState(null);

  useEffect(() => {
    fetchCities();
    calculateDistance();
  }, [distance]);

  const fetchCities = async () => {
    try {
      const response = await axios.get('https://geolocation-db.com/json/');
      const countryCode = response.data.country_code;

      const citiesResponse = await axios.get(
        `https://geolocation-db.com/json/countries/${countryCode}`
      );
      const citiesData = citiesResponse.data.cities;
      // console.log(citiesData)
      setCities(citiesData);
    } catch (error) {
      // console.error(error);
    }
  };

  const calculateDistance = () => {
    const selectedCity1 = cities.find((city) => city.city === city1);
    const selectedCity2 = cities.find((city) => city.city === city2);

    if (selectedCity1 && selectedCity2) {
      const lat1 = selectedCity1.latitude;
      const lon1 = selectedCity1.longitude;
      const lat2 = selectedCity2.latitude;
      const lon2 = selectedCity2.longitude;

      const R = 6371; // Radius of the Earth in kilometers
      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;

      setDistance(distance.toFixed(2));
    } else {
      console.error('Latitude and longitude data not found for selected cities');
    }
  };

  const calculateCarbonFootprint = () => {
    // Assume a carbon footprint of 0.25 kg CO2 per kilometer for flight travel
    const carbonFootprint = distance * 0.25;
    setCarbonFootprint(carbonFootprint);
  };

  const handleCalculateClick = (e) => {
    e.preventDefault()
    
    calculateCarbonFootprint();
  };

  const toRadians = (angle) => {
    return (angle * Math.PI) / 180;
  };

  return (
    <>
      <OutletAnimation>
        <span id="foot-type-head">Flight</span>

        <div className="container">
          <form className="p-2 carbon-forms">
            {/* from destination */}
            <label className="mt-4">From</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={city1} onChange={(e) => setCity1(e.target.value)}
            >
              <option value="" selected className="enterstart">
                Enter Start Point
              </option>
              {cities && cities.map((city, index) => (
          <option key={index} value={city.city}>
            {city.city}
          </option>
        ))}
            </select>
            {/* end from Destination */}
            {/* To Destination */}
            <label className="mt-4">To</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={city2} onChange={(e) => setCity2(e.target.value)}
            >
              <option selected value="">
                Open this select menu
              </option>
              {cities && cities.map((city, index) => (
          <option key={index} value={city.city}>
            {city.city}
          </option>
        ))}
            </select>
            <label>Distance : {distance}</label><br />
            <label className="mt-4">Class</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <label className="mt-4">Trips</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <label className="mt-4">Trip Type</label>
            <select className="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <center>
              <button
                onClick={handleCalculateClick}
                className="btn btn-lg btn-outline-dark mt-3 mb-3 calculate-foot"
              >
                Calculate
              </button>

              <h5>
                {carbonFootprint && (
                  <p>
                    Carbon footprint for flight travel: {carbonFootprint} kg CO2
                  </p>
                )}
              </h5>
            </center>
          </form>
        </div>
      </OutletAnimation>
    </>
  );
}

export default Flight;
