import React, { useEffect, useState } from "react";
import "./footprint.css";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarbonFootPrint } from "../../Redux-Toolkit/Slices/BasicPages";
import AccordianForAll, {
  AccordianHead,
  ShowLess,
  ShowMore,
} from "../Accordian/AccordianForAll";
import Loader from "../Loader/Loader";

function FootprintMain() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.basic);
  // console.log(isLoading);
  const carbonData = useSelector((state) => state?.basic?.data);
  const carbonFaq = carbonData?.data?.faqs;
  useEffect(() => {
    dispatch(fetchCarbonFootPrint());
  }, []);

  // sending props of index as expand or not
  const [showFaqs, setshowFaqs] = useState(3);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className="footprint"
            style={{
              backgroundImage: `url(${carbonData?.data?.main_image_path})`,
            }}
          >
            <div
              className="container h-100 d-flex flex-column justify-content-center align-items-center text-center "
              data-aos="fade-up"
            >
              <h1 id="StateName">{carbonData?.data?.title}</h1>
              <h6 id="abt-p">{carbonData?.data?.main_description}</h6>
            </div>
          </div>
          <div className="container">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
            >
              <span className="mt-4 mb-2 footprint-title">
                {carbonData?.data?.sec_one_title}
              </span>
              <div className="mt-4 mb-4 foot-p">
               
                  <div
                    dangerouslySetInnerHTML={{
                      __html: carbonData?.data?.sec_one_description,
                    }}
                  ></div>
                
              </div>
            </div>

            {/* accordian section */}
            <AccordianHead />
            {carbonFaq &&
              carbonFaq
                .slice(0, showFaqs)
                .map((item, index) => (
                  <AccordianForAll
                    questions={item.question}
                    answers={item.answer}
                    isExpanded={index === expandedIndex}
                    onToggle={() => toggleAccordion(index)}
                  />
                ))}
            <center>
              {showFaqs >= carbonFaq?.length ? (
                <button id="btnshowmore" onClick={() => setshowFaqs(3)}>
                  <ShowLess />
                </button>
              ) : (
                <button
                  id="btnshowmore"
                  onClick={() => setshowFaqs((prev) => prev + 2)}
                >
                  <ShowMore />
                </button>
              )}
            </center>
            <Sidebar />
          </div>
        </>
      )}
    </>
  );
}

export default FootprintMain;
