import React , {useEffect} from 'react'
import "./Policy.css"
import { useDispatch , useSelector } from 'react-redux';
import { fetchPrivacyPolicy } from '../../Redux-Toolkit/Slices/BasicPages';
function Policy() {
  const dispatch = useDispatch();

  const privacyPolicy = useSelector((state) => state.basic.data);
  // console.log(privacyPolicy, "pp");

  useEffect(() => {
    dispatch(fetchPrivacyPolicy());
  }, []);
  return (
    <>
      <div className="privacy-policy">
        <div className="container h-100 d-flex flex-column justify-content-center align-items-center text-center ">
          <h1 id="StateName">{privacyPolicy.data && privacyPolicy.data.title}</h1>
          <h6 id="abt-p">
            {
              privacyPolicy.data && privacyPolicy.data.subtitle
            }
          </h6>
        </div>
      </div>

      <div className="container mt-5 mb-5 policy">
        {
          privacyPolicy.data && <div dangerouslySetInnerHTML={{__html: privacyPolicy.data.description}}></div>
        }
      </div>
    </>
  );
}

export default Policy