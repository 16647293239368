import React , {useState} from 'react'
import { NavLink , Outlet } from 'react-router-dom';

function Sidebar() {
  const [isactive, setisactive] = useState("flight")
 
  return (
    <>
      <div className="myside mt-5 mb-5" >
        <span id="calculate2">Calculate your Carbon Footprints</span>
        <div className="sidebar" data-aos="fade-right">
          <span id="calculate">Calculate your Carbon Footprints</span>
          <NavLink to="flight" className={isactive === "flight" ? "active" : null} onClick={() => setisactive("flight")}>
            Flight
          </NavLink>
          <NavLink to="Train" className={isactive === "train" ? "active" : null}  onClick={() => setisactive("train")}>
            Train
          </NavLink>
          <NavLink to="Bus" className={isactive === "bus" ? "active" : null} onClick={() => setisactive("bus")}>
            Bus
          </NavLink>
          <NavLink to="car" className={isactive === "car" ? "active" : null} onClick={() => setisactive("car")}>
            Car
          </NavLink>
        </div>
        <div className="mycontent" data-aos="fade-left">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Sidebar