import logo from "./logo.png"
import Slide1 from "./Sliderimage.png"
import mobile from "./mobile.png"
import kroger from "./kroger.png"
import eight from "./8x8.png"
import sony from "./Sony.png"
import network from "./Networks.png"
import global from "./GlobalLogic.png"
import train from "./train.png"
import plane from "./plane.png"
import bus from "./bus.png"
import footerlogo from "./footerLogo.png"
import md from "./mini-destination.png"
import pin from "./pin.png"
import call from "./Group.png"
import mail from "./mail.png"
import quote1 from "./quote1.png"
import quote2 from "./quote2.png"
import home from "./home.png"
import j1 from "./j1.png"
import j2 from "./j2.png"
import j3 from "./j3.png"
import mapp from "./map.png"
import lang from "./lang.png"
import pop from "./population.png"
import btime from "./time.png"
import sou from "./sou.png"
import guju from "./guju.png"
import gandhi from "./gandhi.png"
import money from "./money.png"
import winters from "./wintery.png"
import rain from "./rainny.png"
import sun from "./sunny.png"
import hyatt from "./hyatt.png"
import sideimg from "./sideimage.png"
import imaps from "./imps.png"
export const Images = {
    logo,
    Slide1,
    mobile,
    imaps,
    kroger,
    eight,
    sony,
    network,
    global,
    train,
    plane,
    bus,
    footerlogo,
    md,
    pin,
    call,
    mail,
    quote1,
    quote2,
    home,
    j1,
    j2,
    j3,
    lang,
    mapp,
    btime,
    pop,
    sou,
    guju,
    gandhi,
    money,
    sun,
    rain,
    winters,
    hyatt,
    sideimg
}