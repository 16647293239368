import React, { useEffect, useState } from "react";
import "./journey.css";
import { Images } from "../../Images/ImageIndex";
import { useDispatch, useSelector } from "react-redux";
import { fetchOurJourney } from "../../Redux-Toolkit/Slices/BasicPages";
import AccordianForAll, {
  AccordianHead,
  ShowLess,
  ShowMore,
} from "../Accordian/AccordianForAll";
import Loader from "../Loader/Loader";

function OurJourney() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.basic.isLoading);
  const ourJourney = useSelector((state) => state.basic.data);
  const journeyFaqs = ourJourney?.data?.faqs;

  useEffect(() => {
    dispatch(fetchOurJourney());
  }, []);

  // sending props of index as expand or not
  const [showFaqs, setshowFaqs] = useState(3);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="ourjourneys"  style={{
          backgroundImage: `url(${ourJourney?.data?.main_image_path})`,
        }}>
        <div
          className="container h-100 d-flex flex-column justify-content-center align-items-center text-center "
          data-aos="fade-up"
        >
          <h1 id="StateName">{ourJourney?.data?.title}</h1>
          <h6 id="abt-p">{ourJourney?.data?.main_description}</h6>
          <span className="mt-4 welcome">Welcome to Trouvaille Trips</span>
        </div>
      </div>
      <div className="container">
        <div className="myfirstjr mt-4">
          <img src={Images.j1} id="j1" />
          <div className="jr-desc" data-aos="fade-right">
            <div className="letstart">
              <div className="d-flex">
                <div className="circular">1</div>
                <span id="l-title">{ourJourney?.data?.sec_one_title}</span>
              </div>

              <p
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: ourJourney?.data?.sec_one_description,
                }}
              ></p>
            </div>
          </div>
        </div>
        {/* second */}
        <div className="mysecond">
          <div className="sr-desc" data-aos="fade-left">
            <div className="letstart2 p-4">
              <div className="d-flex">
                <div className="circular">2</div>
                <span id="l-title">{ourJourney?.data?.sec_two_title}</span>
              </div>
              <p
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: ourJourney?.data?.sec_two_description,
                }}
              ></p>
            </div>
          </div>
          <img src={Images.j2} alt="" id="j2" />
        </div>
        {/* third */}
        <div className="myfirstjr third">
          <img src={Images.j1} id="j1" />
          <div className="jr-desc" data-aos="fade-right">
            <div className="letstart">
              <div className="d-flex">
                <div className="circular">3</div>
                <span id="l-title">
                  {ourJourney.data && ourJourney.data.sec_three_title}
                </span>
              </div>
              <p
                className="mt-2"
                dangerouslySetInnerHTML={{
                  __html: ourJourney?.data?.sec_three_description,
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>

      {/* accordian section */}
      <AccordianHead />
      <div className="container">
        {journeyFaqs?.map((item, index) => (
            <AccordianForAll
              questions={item.question}
              answers={item.answer}
              isExpanded={index === expandedIndex}
              onToggle={() => toggleAccordion(index)}
            />
          ))}
      </div>
      <center>
        {showFaqs >= journeyFaqs?.length ? (
          <button id="btnshowmore" onClick={() => setshowFaqs(3)}>
            <ShowLess />
          </button>
        ) : (
          <button
            id="btnshowmore"
            onClick={() => setshowFaqs((prev) => prev + 2)}
          >
            <ShowMore />
          </button>
        )}
      </center>
    </>
  );
}

export default OurJourney;
