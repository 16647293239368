import "../Home/StartJourney.css";
import {useNavigate } from "react-router-dom";
import ArrowSVG from "../UI/ArrowSVG";


function StartJourney() {
  const navigate = useNavigate();
  function gotoContact() {
    navigate("/contact");
    window.scrollTo({ top: 0, behavior: "auto" });
  }
  return (
    <>
      <div className="clouds mt-5">
        <div className="animation">
          <div className="cloud cloud1" />
          <div className="cloud cloud2" />
          <div className="cloud cloud3" />
        </div>
        <div className="container p-5 journey-title" data-aos="fade-up">
          <h3 classname="mt-5">Start Your Journey With Us!</h3>
          <div className="divider" />
          <p classname="journey-text">
            Please Contact us to planning your special planning of vacation of
            nature locations.
          </p>
          <center>
            <button className="gotocontact" onClick={gotoContact}>
              Contact
            <ArrowSVG color="black"/>
            </button>
          </center>
        </div>
      </div>
    </>
  );
}

export default StartJourney;
