// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Images/pp.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".privacy-policy {\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position:center;\r\n  height: 100vh;\r\n  max-width: auto;\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n  .privacy-policy{\r\n    height: 55vh;\r\n  }\r\n}\r\n\r\n.policy p{\r\n    font-family: \"Lexend\";\r\n    font-size: 1.3rem;\r\n}", "",{"version":3,"sources":["webpack://./src/Components/PrivacyPolicy/Policy.css"],"names":[],"mappings":"AAAA;EACE,mDAAoC;EACpC,sBAAsB;EACtB,4BAA4B;EAC5B,0BAA0B;EAC1B,aAAa;EACb,eAAe;AACjB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".privacy-policy {\r\n  background: url(../../Images/pp.png);\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n  background-position:center;\r\n  height: 100vh;\r\n  max-width: auto;\r\n}\r\n\r\n@media screen and (max-width: 991px) {\r\n  .privacy-policy{\r\n    height: 55vh;\r\n  }\r\n}\r\n\r\n.policy p{\r\n    font-family: \"Lexend\";\r\n    font-size: 1.3rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
