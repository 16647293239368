import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "./accordian.css"

// Questions - Title of accordian
export function AccordianHead(){
  return (
    <div className="text-center mt-5" data-aos="fade-up">
    <span id="questions">Questions??? Look here.</span>
  </div>
  )
}

// export showMore
export const ShowMore= () => {
  const btnShowMore = "<<< Show More >>>";
  return btnShowMore
}

export const ShowLess = () => {
  const btnShowLess = "<<< Show Less >>>";
  return btnShowLess
}


// Accordian Q/A
function AccordianForAll({
  questions = "",
  answers = "",
  isExpanded,
  onToggle 
}) {
 
  const handleToggle = () => {
    onToggle();
  };

  
  return (
    <>

      <div className="wrapper mt-4 mb-4">
        <div className="accordian" data-aos="fade-up">
          <div className="item">
            <div className="title" onClick={handleToggle}>
              <span>
                {isExpanded ? (
                  <FontAwesomeIcon icon={faMinus} color="black" />
                ) : (
                  <FontAwesomeIcon icon={faPlus} color="black" />
                )}
              </span>
              <span id="que">{questions}</span>
            </div>
            <div className={isExpanded ? "content show" : "content"}>
              <p id="ans" className="px-5"> {answers} </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccordianForAll;
