// ExploreMoreModal.js
import React from "react";
import { useNavigate } from "react-router-dom";

function ExploreMoreModal({ activeDestination, showModal, closeModal }) {
  const navigateToThatPlace = useNavigate();
  return (
    showModal && (
      <>
        <div className="modal-wrapper" onClick={closeModal}></div>
        <div className="modal-container" data-aos="fade-up">
          <div className="modal-heading">
            <h6>Travel Around The World</h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <hr />
          <div class="content-wrapper">
            {activeDestination &&
              activeDestination.map((item, index) => (
                <div class="news-card" key={index}>
                  <img src={item.banner} alt="" class="news-card__image" />
                  <div
                    class="news-card__text-wrapper"
                    onClick={() => {
                      navigateToThatPlace(`/destination/${item.id}/${item.name}`);
                      window.scrollTo({
                        top: 0,
                        behavior: "auto",
                      });
                      closeModal();
                    }}
                  >
                    <h2 class="news-card__title">{item.name}</h2>
                    <div class="news-card__details-wrapper">
                      <p class="news-card__excerpt">
                        <p
                          dangerouslySetInnerHTML={{ __html: item.tagline }}
                        />
                      </p>
                      <button
                        class="news-card__read-more"
                        onClick={() => {
                          navigateToThatPlace(`/destination/${item.id}`);
                          window.scrollTo({
                            top: 0,
                            behavior: "auto",
                          });
                          closeModal();
                        }}
                      >
                        Explore &raquo;
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    )
  );
}

export default ExploreMoreModal;
